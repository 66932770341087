<template>
  <b-card
    :flat="true"
    no-body
    :class="`${true ? 'shadow-none' : ''}`"
    style="height: 100%"
  >
    <b-row class="p-1">
      <div class="ml-1 mt-1 mb-1 text-lg font-bold">Start Hamilton Crawler</div></b-row
    >

    <b-row class="p-1">
      <b-col lg="4">
        
        <div>
          <div :class="`${dateTimeError ? 'text-red-400' : ''}`">
            <span v-if="dateTimeError">no date selected </span>
            <span v-else>Select Crawl Time / Date </span>
          </div>
          <b-row>
            <b-form-timepicker v-model="selectedTime" locale="en" size="sm" class="ml-1 mb-1"></b-form-timepicker>
   
          </b-row>
          <b-row>
            <b-col>
              <b-calendar
                v-model="selectedDate"
                locale="en-US"
                @context="onContext"
                
              ></b-calendar>
            </b-col>
      
          </b-row>

        </div>
      </b-col>
      <b-col lg="4">
        <div>
          <div :class="`${selectedDomainError ? 'text-red-400' : ''}`">
            <span v-if="selectedDomainError">no domains included </span>
            <span v-else>Domains</span>
          </div>
         1: <b-form-input v-model="domain1" type="text" class="form-control" placeholder="@" size="sm"></b-form-input>
          2: <b-form-input v-model="domain2" type="text" class="form-control" placeholder="@" size="sm"></b-form-input>
        </div>
      </b-col>
      <b-col lg="4">
        <div>
          <div class="invisible">button</div>
          <div v-if="crawlMessage">{{crawlMessage}}</div>
          <b-button v-else size="sm" @click="startCrawler()"> Start Crawler </b-button>
        </div></b-col>
      <b-col class="mt-10">
       </b-col
      >
    </b-row>
  </b-card>
</template>
    
    
    <script>
import {
  BCol,
  BRow,
  BCard,
  BButton,
  BCalendar,
  BFormTimepicker,
  BFormInput
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,

    BCard,
    //BCardBody,
    BButton,


    BCalendar,
    BFormTimepicker,
    BFormInput
  },
  data() {
    return {

      crawlMessage: '',
        selectedTime: '',
        selectedDate: '',
        contex: null,
        domain1: '',
        domain2: '',
      file1: null,
      endpointBusy: true,
      selectedCounty: "select a county",
      selectedCountyOptions: [],
      uploadError: null,
      errorMessage: "no file chosen",
      dateTimeError: null,
      selectedType: "choose a type",
      time: 0,
      isRunning: false,
      interval: null,
      busy: false,
      show: false,
      processing: [],
      failed: [],
      complete: [],
      typeOptions: [
        "choose a type",
        "filed",
        "served",
        "hearing",
        "disposed",
        "garnish",
      ],
      selectedDomainError: null,
      adminTemplateId: "",
      adminTemplates: [],
    };
  },
  watch: {},
  created() {},
  methods: {
    startCrawler() {
      var l1 = this;

      if (!l1.selectedTime || !l1.selectedDate) {
        l1.dateTimeError = true;
      } else {
        l1.dateTimeError = false;
      }

      if (!l1.domain1 || !l1.domain2) {
        l1.selectedDomainError = true;
      } else {
        l1.selectedDomainError = false;
      }

      if (l1.adminTemplateId) {
        l1.uploadError = true;
      } else {
        l1.uploadError = false;
      }

      if (l1.selectedDate && l1.selectedTime && l1.domain1 && l1.domain2) {
        l1.crawlMessage = "SUBMITTING"
        l1.$AuthAxios.instance
          .post("/api-access/tn_crawl_api/start-crawler/", {
            params: {
              dateTime: `${l1.selectedDate} ${l1.selectedTime}`,
              domain1: l1.domain1,
              domain2: l1.domain2,
            },
          })
          .then(function () {
            l1.crawlMessage = "SUCCESS !!"
          })
  
      }
    },
    onContext(ctx) {
        this.context = ctx
      }

  },
};
</script>
  
    